<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">开票脚本</el-breadcrumb-item>
        <el-breadcrumb-item>导入开票数据</el-breadcrumb-item>
      </el-breadcrumb>
    </template>

    <div class="storedialog load-dialog" style="text-align: center; max-width:600px; margin:0 auto;">
      <div class="download" style="margin: 10px auto">
        点击下载模板：
        <a target="_blank" :href="templateUrl"> 批量开票导入模板 </a>
      </div>

      <el-upload v-loading="loading" class="upload-box" ref="upload" action="#" :http-request="submitUpload" :auto-upload="true" drag :limit="1">
        <el-icon style="font-size:3em;">
          <UploadFilled />
        </el-icon>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip">
          注：Excel表格里的填写项请严格按照模板内容进行填写
        </div>
      </el-upload>
    </div>

    <div style="margin-top:30px;">
      <el-input type="textarea" v-model="scriptText" :rows="6" style="width: 98%"></el-input>
    </div>
  </el-card>
</template>

<script>
import { uploader } from "@/api/subupload";
import { createTaxScript } from "@/api/stock";
export default {
  name: "taxticket",
  data() {
    return {
      templateUrl:
        process.env.VUE_APP_BASEURL +
        "/static/template/taxscript2023.xlsx",
      loading: false,
      scriptText: ''
    };
  },
  methods: {
    //上传操作
    submitUpload(file) {
      this.loading = true;
      var dataList = new FormData();
      dataList.append(file.file.name, file.file);
      dataList.append("fileType", "excel");
      dataList.append("dataType", "Taxticket");
      this.$refs.upload.clearFiles();

      uploader(dataList)
        .then((res) => {
          if (res.code === 200) {
            this.$message({ message: "上传成功", type: "success" });
            this.dataId = res.data[0].id;
          } else {
            this.$message.error("上传失败，请重试");
          }
        })
        .then(() => {
          this.loading = false;
          createTaxScript(this.dataId).then(
            (res) => {
              if (res.code === 200) {
                console.log(res.data);
                this.scriptText = res.data;
              }
            },
            (err) => {
              console.log(err);
            }
          );
        });
    },

    back() {
      this.$router.go(-1);
    },
  },
  mounted() { },
};
</script>
<style lang="less">
</style>